<template>
	<w-dialog
		:max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : null"
		:title="action == 'create' ? $t('offers.create_category') : $t('offers.update_category')"
		:value="opened"
		@input="closeDialog()"
	>
		<w-layout row wrap>
			<w-flex px-1 grow>
				<v-text-field ref="categoryName" v-model="category.name" autofocus :label="$t('name') + ' *'" maxlength="191" type="text" />
			</w-flex>
			<w-flex px-1 shrink>
				<v-text-field
					v-if="action != 'update'"
					v-model="category.abbreviation"
					:label="$t('abbreviation') + ' *'"
					:loading="abbreviations_loading"
					mask="AAA"
					maxlength="3"
					:rules="[validateAbbreviation]"
					type="text"
				/>
				<v-text-field
					v-else
					v-model="category.abbreviation"
					hide-selected
					hide-details
					full-width
					readonly
					disabled
					:label="$t('abbreviation') + ' *'"
					:loading="abbreviations_loading"
					mask="AAA"
					type="text"
				/>
			</w-flex>
		</w-layout>
		<w-layout row align-center>
			<w-color-picker v-model="category.color"  :label="$tc('color', 1)" />
		</w-layout>
		<template v-slot:actions>
			<v-spacer />
			<w-btn
				:disabled="
					!category.abbreviation ||
					category.abbreviation.length === 0 ||
					!category.name ||
					category.name.length === 0 ||
					typeof validateAbbreviation == 'string'
				"
				flat
				right
				@click="method"
			>
				{{ $t('actions.validate') }}
			</w-btn>
		</template>
	</w-dialog>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'EditCategory',
	mixins: [OffersModuleGuard],
	props: {
		action: {
			required: true,
			type: String
		},
		opened: {
			default: () => false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			abbreviations_loading: false,
			category: {
				color: '#000000'
			},
			existing_abbreviations: []
		}
	},
	computed: {
		method: function () {
			let result = function () {}
			if (this.action == 'create') {
				result = this.save
			} else if (this.action == 'update') {
				result = this.update
			}
			return result
		},
		validateAbbreviation: function () {
			let result = true
			if (this.action == 'create' && this.existing_abbreviations.includes(this.category.abbreviation)) {
				result = this.$t('offers.existing_abbreviation')
			}
			return result
		}
	},
	watch: {
		opened: {
			handler: function (val) {
				if (val) {
					if (this.action == 'create') {
						this.category = {}
						if (this.existing_abbreviations.length === 0) {
							this.getAbbreviations()
						}
					} else {
						this.category = { ...this.value }
					}
					this.$nextTick(() => {
						this.$refs.categoryName.focus()
					})
				}
			}
		}
	},
	methods: {
		closeDialog: function () {
			this.$emit('close')
		},
		getAbbreviations: function () {
			this.existing_abbreviations = this.service.getAbbreviations()
		},
		save: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.service
				.createCategory(this.accountingFirmId, this.category)
				.then(() => {
					this.$emit('load')
					this.closeDialog()
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		},
		update: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.service
				.updateCategory(this.accountingFirmId, this.category.id, this.category)
				.then(() => {
					this.closeDialog()
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		}
	}
}
</script>